<template>
      <section class="appie-services-2-area appie-services-8-area pt-90 pb-55" id="service">
        <div class="container">
           
            <div class="row align-items-center">
                <div class="col-lg-7">
                   <div class="row align-items-end">
                        <div class="col-lg-11 col-md-8">
                            <div class="appie-section-title">
                                <h3 class="appie-title ">¿QUÉ ES CUBIC?</h3>
                                <p class="text-qec py-3"> 
                                    Es un Holograma 3D que puede transportar una persona a cuerpo
                                    completo desde otro lugar, así como un expositor que esté en más de
                                    5 paises exponiendo al mismo tiempo en vivo,
                                    y con la posibilidad de escuchar a su audiencia y verlos, ya que
                                    <strong>Cubic</strong> cuenta con una cámara 360º
                                </p>
                                <p class="text-qec"> 
                                    También brinda una serie de otras funcionalidades, como pantalla
                                    publicitaria 3D o contenido pre-grabado haciendo playback y
                                    una de las cualidades que mas destacan es la integración de
                                    inteligecia artificial.
                                </p>
                    
            
                         </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-thumb">
                        <img src="@/assets/images/CUBIC_3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.text-qec {
  text-align: justify;
  color: black;
}
</style>