
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeOne from '../views/HomeEight.vue'
/*import News from '../views/News.vue'
import SingleNews from '../views/SingleNews.vue'
import Contact from '../views/Contact.vue'
import ServiceDetails from '../views/ServiceDetails'
import Error from '../views/Error.vue'
import AboutUs from '../views/About.vue'
import Shops from '../views/Shops.vue'
import ShopDetails from '../views/ShopDetailsPage.vue'
*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: HomeOne
  }/*,
  {
    path: '/service-details',
    name: 'ServiceDetails',
    component: ServiceDetails
  },
  {
    path: '/about-us',
    name: 'Acerca de nosotros',
    component: AboutUs
  },
  {
    path: '/shops',
    name: 'Tienda',
    component: Shops
  },
  {
    path: '/shop-details',
    name: 'Detalles de la tienda',
    component: ShopDetails
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/single-news',
    name: 'SingleNews',
    component: SingleNews
  },
  {
    path: '/contact',
    name: 'Contacto',
    component: Contact
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }*/
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
