<template>
      <section class="appie-services-4-area appie-services-8-area pt-90 pb-55" id="service">
        <div class="container">
           
            <div class="row align-items-center">
                <div class="col-lg-7">
                   <div class="row align-items-end">
                        <div class="col-lg-11 col-md-8">
                            <div class="appie-section-title">
                                <h3 class="appie-title blanco">CUBIC CONECTA</h3>
                                <h1 class="titulo"> STREAMING - COMUNICACIÓN EN TIEMPO REAL</h1>
                                <div class="no">
                                    
                                </div>
                                <p class="text-justify py-3"> 
                                    Con <strong>CUBIC CONECTA</strong>, traspasa fronteras y sé parte de cualquier
                                    evento en tiempo real. Transmitiendo como un holograma desde
                                    tu ubicación actual, esta modalidad de <strong>streaming</strong> revoluciona la
                                    comunicación, conectándote con tu audiencia sin límites
                                    geográficos. Experimenta la inmersión holográfica y sorprende
                                    a tu público <strong>desde cualquier lugar del mundo</strong>.
                                </p>
                      
                    
            
                         </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.text-justify {
  text-align: justify;
}
.no{
   height: 10px;
}
</style>