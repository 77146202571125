<template>
      <section class="appie-services-5-area appie-services-8-area pt-90 pb-55 " id="service">
        <div class="container">
           
            <div class="row align-items-center">
                <div class="col-lg-7 ">
                   <div class="row align-items-end">
                        <div class="col-lg-11 col-md-8">
                            <div class="appie-section-title">
                                <h3 class="appie-title blanco">CUBIC MEDIA</h3>
                                <h1 class="titulo"> PUBLICIDAD - SOCIAL MEDIA</h1>
                                <p class="text-justify py-3"> 
                                    Experimenta la <strong>revolución holográfica</strong>. Sumérgete en una
                                    experiencia inmersiva de alta definición con <strong>proyecciones
                                    holográficas de videos pregrabados y contenido multimedia</strong>.
                                    Personaliza mensajes y contenidos para <strong>eventos, conciertos,
                                    publicidades </strong>o cualquier ocasión especial. Cubic Media te
                                    brinda una nueva dimensión de entretenimiento y comunicación,
                                    llevando tus presentaciones y eventos a un nivel nunca antes
                                    visto. ¡Sorprende y cautiva a tu audiencia con lo último en
                                    tecnología holográfica!
                                </p>
                      
                    
            
                         </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.text-justify {
  text-align: justify;
}

.titulo{
    color: white ;
    font-size: 20px;
    padding-top: 5px;
    
}


</style> 
<!--====== color para titulo //#E6FDFE ======-->