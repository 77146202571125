<template>
     <section class="appie-services-3-area appie-services-8-area pt-90 pb-55 " id="service">
        <div class="container">
           
            <div class="row align-items-center">
                <div class="col-lg-7"> 
                    <img src="@/assets/images/IA.png" >
                   <div class="row align-items-end">   
                        <div class="col-lg-11 col-md-8">
                            <div class="appie-section-title">
                                <h3 class="appie-title blanco">CUBIC  DAL-IA</h3>
                                <h1 class="titulo "> INTELIGENCIA ARTIFICIAL</h1>
                                <p class="text-justify py-3"> 
                                    La avanzada <strong>inteligencia holográfica</strong> que transforma la interacción.
                                    Experimenta conversaciones fluidas y cercanas con respuestas casi
                                    humanas. Impulsa la excelencia en atención al cliente y aumenta la
                                    eficiencia operativa. Descubre una nueva era de innovación con
                                    Cubic Dalia, el poder de la <strong>IA holográfica al servicio de tu negocio.</strong> 
                                    Aprovecha esta tecnología líder en el mercado para llevar tus
                                    interacciones al siguiente nivel y destacar en la industria.
                                </p>
                      
                    
            
                         </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-thumb">
                        <img src="@/assets/images/DALIA.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.blanco{
    color: white;
}

.text-justify {
  text-align: justify;
  color: white;
}



</style>