<template>
  
    <section class="appie-about-8-area pt-60 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title mb-30">
                        <h3 class="appie-title">¡SUMÉRGETE EN UNA <br>EXPERIENCIA INMERSIVA! </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="appie-about-8-box">
                            <img class="imagen1" src="@/assets/images/1.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-about-8-box">
                            <img src="@/assets/images/2.png" alt="">
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.negroyespacio{
    color: black;
    padding-top: 35px;
}
.imagen1{
   /**display: grid;
    place-items: center; */ 
    padding-top: 20px;
    height: 300px;
}
</style>