<template>
  <div class="appie-signup-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-signup-box">
            <h3 class="title">CONTÁCTANOS</h3>
            <form action="#">
              
              <div class="input-box d-none d-md-block" :style="{ width: 820 + 'px' }">
                <input  v-model="correo" class="d-none d-md-block " :style="{ width: 800 + 'px' }"  type="email" placeholder="Ingrese el correo electronico" />
              </div>

              <div class="input-box d-md-none">
                <input class="d-md-none"   v-model="correo" type="email" placeholder="Ingrese el correo electronico" />
              </div>


              <div class="input-box">
                <button @click="onClick">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      correo: "" // Propiedad para almacenar el valor del correo
    };
  },
  methods: {
    onClick() {
      
      window.open(this.contactar(this.correo));
      
      // Llama a la función contactar con el valor del correo
    },
    contactar(correo) {
     
      const linkws = "https://wa.me/56944455183?text=Hola+contáctame+a+mi+correo+" + correo
      console.log(linkws)
      // Aquí puedes agregar cualquier otra lógica que necesites con el correo
      return linkws
      
    }
  },
  
};
</script>

<style>

</style>