<template>
  
    <div class="appie-testimonial-about-area pb-45">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title "> LO QUE OPINAN <br>NUESTROS CLIENTES</h3>
                        <p> </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="testimonial-about-slider-active" style="position :relative">
                        <span @click.prevent="showPrev" class="prev slick-arrow" style="display: block;"><i class="fal fa-arrow-left"></i></span>
                        <VueSlickCarousel v-bind="settingsForArticle" ref="articleCarosel">
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                    <p>Ven y disfruta del living de Chaleco López, un espacio dentro del Museo del Automóvil en el Mall Sport. Es una oportunidad única para los aficionados de mi carrera en competencias de Rally. Descubre la trayectoria y logros , un destacado piloto. ¡No te pierdas esta emocionante experiencia! </p>
                                    <p class="texto-izq">-Chaleco López</p>
                                    <p class="texto-izq">Piloto Profesional de Chile</p>
                                    <div class="ratings-icon">
                                        <ul>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                               
                                    <p>La vanguardia de la tecnología: el primer holograma a escala real en Chile. Acabo de probarlo y es una experiencia increíble, como estar en el futuro. Es impresionante esta innovadora muestra. ¡No te lo pierdas!</p>
                                    <p class="texto-izq">-Karol "Dance" Lucero</p>
                                    <p class="texto-izq">Presentador de televisión</p>
                                     <div class="ratings-icon">
                                        <ul>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                
                                    <p>En el Movistar Arena generó furor el show del holograma de Cris MJ. Demasiada gente esperando sacarse una fotografía con el Cubic Hologram. Aquel día del evento, generó mucho contenido en redes sociales hacia nuestro artista. La gente anda buscando cosas innovadoras. ¡Gran éxito!</p>
                                    <p class="texto-izq">-Star Music Chile</p> 
                                    <p class="texto-izq">Equipo de producción de Cris MJ</p>
                                    <div class="ratings-icon">
                                        <ul>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                            <li><i class="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </VueSlickCarousel>
                        <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8 col-sm-10 d-none">
                    <div class="fijar">
                        <button class="Boton">VER TODOS <br> LOS COMENTARIOS</button>        
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
        components:{ VueSlickCarousel},
        data(){ 
            return{
                settingsForArticle: {
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                settingsForPeople:{
                    centerMode: true,
                    centerPadding: '0px',
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            }
        },
        methods:{ 
            showNext() {
                this.$refs.articleCarosel.next()
                this.$refs.peopleCarosel.next()
            },
            showPrev(){
                this.$refs.articleCarosel.prev()
                this.$refs.peopleCarosel.prev()
            },
        }


}
</script>

<style>
.testimonial-about-slider-active .testimonial-box{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.slick-slide{
    padding: 0;
}
.texto-izq{
    text-align: left;
}

</style>