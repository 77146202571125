<template>
      <section class="appie-footer-area appie-footer-about-area appie-footer-8-area">
        
  
        <div class="container pt-20 pr-30 pb-20 pl-30 caja">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="#"><img src="@/assets/images/CUBIC_LOGO.png" alt=""></a>
                        </div>
                        <p>Súmate a la vanguardia holográfica</p>
                        <a class="d-none" href="#"><strong>Leer mas </strong><i class="fal fa-arrow-right"></i></a>
                        <div class="social mt-30 ">
                            <ul>
                                <li><a href="https://www.instagram.com/cubichologram/?fbclid=IwAR3br-H7_Vuv5nCv2A_Azm7ZUVqsV5zDkjBRJ_K3HbDI-5aQ-VXmlvs5Eqs"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a></li>
                                <li><a href="https://www.tiktok.com/@cubichologram?refer=creator_embed"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg></a></li>
                                <li><a href="https://www.facebook.com/cubichologram"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg></a></li>
                                <li><a class="blanco" href="https://twitter.com/cubichologram?s=21&t=W_akZhTWgwJiUCwDVhOr9Q">𝕏</a></li>
                                <li><a href="https://www.youtube.com/@CubicHologram/videos"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a></li>
                                <li class="d-none"><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 d-none  ">
                    <div class="footer-navigation ">
                        <h4 class="title">Compañia</h4>
                      <ul>
                            <li><router-link to="/about-us">Acerca de nosotros</router-link></li>
                            <li><router-link to="/service-details">Servicios</router-link></li>
                            <li><a href="#">Case Studies</a></li>
                            <li><router-link to="/news">Blog</router-link></li>
                            <li><router-link to="/contact">Contacto</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 d-none ">
                    <div class="footer-navigation">
                        <h4 class="title">Soporte</h4>
                        <ul>
                            <li><a href="#">Comunidad</a></li>
                            <li><a href="#">Recursos</a></li>
                            <li><a href="#">Faqs</a></li>
                            <li><a href="#">Politica de privacidad</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                    </div>                    
                </div>
                <div class="col-lg-6 col-md-6 ">
                    <div class="footer-widget-info">
                        <h4 class="title">Contactos</h4>
                        <ul>
                            <li><a><i class="fal fa-envelope"></i> contacto@cubichologram.com</a></li>
                            <li><a href="https://api.whatsapp.com/send/?phone=56944455183&text=Hola%21+Quisiera+m%C3%A1s+informaci%C3%B3n+sobre+Cubic+Hologram&type=phone_number&app_absent=0"><i class="fal fa-phone"></i> +(56) 9 4445 5183</a></li>
                            <li><a href="https://www.google.com/maps/place/SIT/@-33.4273733,-70.6095515,21z/data=!4m14!1m7!3m6!1s0x9662cf956bfebc4f:0xdf107ac459760a39!2sSIT!8m2!3d-33.4273545!4d-70.6093033!16s%2Fg%2F11n14778zy!3m5!1s0x9662cf956bfebc4f:0xdf107ac459760a39!8m2!3d-33.4273545!4d-70.6093033!16s%2Fg%2F11n14778zy?entry=ttu"><i class="fal fa-map-marker-alt"></i> Europa #2102, Providencia, Santiago, Chile </a></li>
                        </ul>
                    </div>
                </div>
            </div> 
            
                <div class="copyright-text pt-4">
                     <p>Copyright © 2023 Cubic Hologram. Todos los derechos reservados.</p>
                </div>    
        </div>
            <div class="footer-copyright d-flex align-items-center justify-content-between pt-0">
                
            </div>
            
          
       
   
    </section>
</template>

<script>
export default {

}
</script>

<style>
.caja{
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    border-color: black;
    display: grid;
    place-items: center;
 /** Cambiar el display y place item si se quiere mostrar Comunidad y Soporte, junto con el col-lg-6 a col-lg-4 */
}
.footer-about-widget .social ul li a.blanco{
    color: white;
}
</style>