<template>
    <!--====== APPIE HERO PART START ======-->
    
    <section class="appie-hero-area appie-hero-8-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="appie-hero-content appie-hero-content-8">                        
                        <h1 class="blanco">DESCUBRE LA EXPERIENCIA <br> CUBIC HOLOGRAM</h1>
                        <ul class="blanco">
                            <li>
                                No te pierdas la oportunidad de ser parte de la vanguardia holográfica <br>en Latinoamérica.
                            </li>
                            <li>
                                Transporta a personas a cualquier lugar y conecta en cualquier parte <br> del mundo.
                            </li>
                        </ul>
                        
                        
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="appie-hero-thumb-6">
                        <div class="thumb text-center wow animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/CUBIC1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>