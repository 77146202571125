<template>
   <div>
    <div v-if="showVideo">
        <div class="mfp-bg mfp-ready" @click.prevent="openVideo"></div>
        <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow: hidden auto;">
            <div class="mfp-container mfp-s-ready mfp-iframe-holder">
                <div class="mfp-content">
                    <div class="mfp-iframe-scaler">
                        <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="openVideo">×</button>
                        <iframe class="mfp-iframe" src="//www.youtube.com/embed/lJ-luyvxcUU?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                        </div>
                    </div>
                <div class="mfp-preloader">Cargando...</div>
            </div>
        </div>
    </div>
    <div class="appie-faq-8-area pt-100 pb-100" id="mycounter">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="appie-section-title">
                        <h3 class="appie-title negro">COMUNICACIÓN DE OTRO NIVEL.</h3>
                        <p class="negro"> <strong>Cubic Hologram </strong>  está cambiando la forma en que el mundo se conecta.</p>
                    </div>
                    
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp wow fadeIn faq-accrodion" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <strong>
                            <ul class="negro">
                                <li>
                                    Publicidad y activaciones
                                </li>
                                <li>
                                    Eventos en vivo y conciertos
                                </li>
                                <li>
                                    Educacion y salud (Seminarios y charlas)
                                </li>
                                <li>
                                    Comunicaciones y humanos Digitales  
                                </li>
                                <li>
                                    Hospitalidad y viajes
                                </li>
                                <li>
                                    Transportes
                                </li>
                                <li>
                                    Informacion
                                </li>
                                <li>
                                    Poder judicial (Mini Cubic)
                                </li>

                            </ul>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-play-box">
            <div class="play-btn">
                <a @click.prevent="openVideo" class="appie-video-popup"><i class="fas fa-play"></i></a>
            </div>
            
        </div>
    </div>
   </div>

</template>

<script>
//import CounDownCom from '../Helper/CounDownCom.vue'
export default {
	//components: { CounDownCom },
    data(){
        return{
            showQuestion:1,
            showVideo:false,
            showCounters: false,
            counterPositions:null,
        }
    },
    mounted() {
        var rec = document.getElementById('mycounter')
        var currentPosition = rec.offsetTop - document.body.scrollTop;
        this.counterPositions=currentPosition
        window.addEventListener('scroll',()=>{
            var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if(this.counterPositions !== null){
                if(currentScrollPosition + 500>this.counterPositions){
                    this.examplem()  
                }
            }
            
        })
    },
    methods: {
        examplem(){
        this.showCounters = true;
        this.counterPositions=null
      },
        OpenQuestion(value){
            this.showQuestion = value
        },
        openVideo(){
            this.showVideo = !this.showVideo
        },
    }   

}
</script>

<style>
.negro{
    color: black;
}
</style>