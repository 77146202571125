<template>
  
  <div class="fondoinicial poppins">
  
    <!--====== PRELOADER PART START ======-->
    <!-- 
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->
    
  
    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->
    
    <!--====== PART START ======-->

    <header-home-eight @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <hero-home-eight />
    
    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE ABOUT 8 PART ENDS ======-->

    <about-home-eight />

    <!--====== APPIE ABOUT 8 PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <services-home-eight />

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->
    <div class="espacio"></div>
    <CubicMedia />
    <div class="espacio"></div>
    <CubicConecta />
    <div class="espacio"></div>
    <cubic-dal-ai />
    <div class="espacio"></div>
    <!--====== APPIE HOW IT WORK PART ENDS ======-->
    
    <work-home-eight />
    <div class="espacio"></div>
    <!--====== APPIE HOW IT WORK PART ENDS ======-->

    <!--====== APPIE get started PART ENDS ======-->

    <get-started-home-eight />
    <!--====== APPIE get started PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->

    <team-home-eight />

    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE TESTIMONIAL PART ENDS ======-->
    <testimonial-home-eight />
    <!--====== APPIE TESTIMONIAL PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <sponser-home-eight class="appie-signup-8-area" />

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-eight />

    <!--====== APPIE FOOTER PART ENDS ======-->
    <a href="https://api.whatsapp.com/send/?phone=56944455183&text=Hola%21+Quisiera+m%C3%A1s+informaci%C3%B3n+sobre+Cubic+Hologram&type=phone_number&app_absent=0" class="float" target="_blank">
    
    <svg class="my-float" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
    </a>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top back-to-top-8">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import AboutHomeEight from "./AboutHomeEight.vue";
import FooterHomeEight from "./FooterHomeEight.vue";
import GetStartedHomeEight from "./GetStartedHomeEight.vue";
import HeaderHomeEight from "./HeaderHomeEight.vue";
import HeroHomeEight from "./HeroHomeEight.vue";
import ServicesHomeEight from "./ServicesHomeEight.vue";
import SponserHomeEight from "./SponserHomeEight.vue";
import TeamHomeEight from "./TeamHomeEight.vue";
import TestimonialHomeEight from "./TestimonialHomeEight.vue";
import WorkHomeEight from "./WorkHomeEight.vue";
import CubicDalAi from "./CubicDal-Ai.vue";
import CubicMedia from "./CubicMedia.vue";
import CubicConecta from "./CubicConecta.vue";

export default {
  components: {
    SidebarHomeOne,
    HeaderHomeEight,
    HeroHomeEight,
    AboutHomeEight,
    ServicesHomeEight,
    WorkHomeEight,
    GetStartedHomeEight,
    TeamHomeEight,
    TestimonialHomeEight,
    SponserHomeEight,
    FooterHomeEight,
    CubicDalAi,
    CubicMedia,
    CubicConecta

},
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "Que es Cubic",
          path: "/"
        },
        {
          name: "Servicios",
          path: "/",
        },
        {
          name: "Clientes",
          path: "/"
        },
        {
          name: "Noticias",
          path: "/",
          
        },
        {
          name: "Contacto",
          path: "/",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
.espacio{
  height: 15px;
}

.fondoinicial{
    background-image: url(../../assets/images/FONDO_GRANDE.jpg);
    background-size: 110%; /* La imagen ocupará el 100% del contenedor */
    background-repeat: no-repeat;
}

.float{
  position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 30px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}
.my-float {
    margin-top: 10px;
    color: white;
    fill: white;
}
.poppins {
  font-family: 'Poppins', sans-serif;
}
</style>