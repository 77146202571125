<template>
      <section class="appie-team-area appie-team-about-area appie-team-8-area pt-90 pb-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title"> EXPERIENCIA CUBIC</h3>
                        <p> </p>
                    </div>
                </div>
            </div>
            <div class="row">
                
                <div class="d-none d-md-block" :style="{ height: 500 + 'px' }" >
                    
                    <div v-if="showVideo">
                            <div class="mfp-bg mfp-ready" @click.prevent="openVideo"></div>
                            <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                                <div class="mfp-container mfp-s-ready mfp-iframe-holder">
                                    <div class="mfp-content">
                                        <div class="mfp-iframe-scaler">
                                            <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="openVideo">×</button>
                                            <iframe class="mfp-iframe" src="//www.youtube.com/embed/lJ-luyvxcUU?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                            </div>
                                        </div>
                                    <div class="mfp-preloader">Cargando...</div>
                                </div>
                            </div>
                        </div>
                        <div class="appie-faq-4-area   " id="mycounter">
                            
                            <div class="faq-play-box" :style="{ height: 500 + 'px' }">
                                <div class="play-btn">
                                    <a @click.prevent="openVideo" class="appie-video-popup"><i class="fas fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                  

                </div>
                <div class="video-container d-sm-block d-md-none">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/lJ-luyvxcUU" frameborder="0" allowfullscreen></iframe>
                </div>
                <div class="col-lg-12">   
                    <div class="team-btn text-center mt-50">
                        <strong>Cubic Hologram</strong> estuvo en la inauguración del <strong>Living del Chaleco López</strong>, <br>
                        ven a vivir la experiencia <strong>CUBIC</strong>, estaremos presente hasta 17 de junio ! 
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            showQuestion:1,
            showVideo:false,
            showCounters: false,
            counterPositions:null,
        }
    },
    mounted() {
        var rec = document.getElementById('mycounter')
        var currentPosition = rec.offsetTop - document.body.scrollTop;
        this.counterPositions=currentPosition
        window.addEventListener('scroll',()=>{
            var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if(this.counterPositions !== null){
                if(currentScrollPosition + 500>this.counterPositions){
                    this.examplem()  
                }
            }
            
        })
    },
    methods: {
        examplem(){
        this.showCounters = true;
        this.counterPositions=null
      },
        OpenQuestion(value){
            this.showQuestion = value
        },
        openVideo(){
            this.showVideo = !this.showVideo
        },
    }   
}
</script>

<style>

</style>